window.jQuery = $;
window.$ = $;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import $ from 'jquery'
import { CopyButtonPlugin } from "./copy"
window.CopyButtonPlugin = CopyButtonPlugin
require("bootstrap")
require("@popperjs/core")
Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", () => {
  setTimeout(function() {
    $('.success, .alert').fadeOut();
  }, 10000);
})
